import Navbar from "@/components/Navbar";
import UserService from "@/services/user.service";
import SnackbarService from "@/services/snackbar.service";
import CircleSvg from "@/components/CircleSvg";
import RateDialog from "@/components/RateDialog";
import EditDialog from "../../components/EditDialog";
import DeleteDialog from "../../components/DeleteDialog";
import OrderDialogCalendar from "@/components/OrderDialogCalendar";
import moment from "moment";
import i18n from "@/plugins/i18n";

export default {
  name: "Orders",
  components: {
    EditDialog,
    RateDialog,
    CircleSvg,
    Navbar,
    DeleteDialog,
    OrderDialogCalendar,
  },
  data() {
    return {
      monthlyCosts: 0,
      dailyCosts: [],
      user: null,
      focus: "",
      x: 0,
      y: 0,
      coc: false,
      clickedDate: null,
      contextMenu: false,
      type: "month",
      notification: "",
      iconNoti: "mdi-close-circle-outline",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      overlay: true,
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      absolute: true,
      products: null,
      disabledNotifications: [],
      dialog: false,
      dialogRate: false,
      visible: false,
      availableDays: [],
      timeout: 1200,
      processingOrder: false,
      updatable: true,
      ratable: true,
      snackbar: {
        icon: "",
        title: "",
        color: "",
      },
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    lessThanTwoWeeks(date) {
      const now = new Date();
      const twoWeeksLater = new Date(now.getTime() + 14 * 24 * 60 * 60 * 1000);
      const myDateObj = new Date(date);
      return myDateObj <= twoWeeksLater;
    },
    myDayFormat(d) {
      switch (d.weekday) {
        case 0:
          return "NED";
        case 1:
          return "PON";
        case 2:
          return "UTO";
        case 3:
          return "SRI";
        case 4:
          return "ČET";
        case 5:
          return "PET";
        case 6:
          return "SUB";
      }
    },
    menuClick() {
      let date = this.clickedDate;
      if (this.notification === i18n.tc("orders.enable")) {
        UserService.enableNotification(date)
          .then(() => {
            this.getNotis();
            SnackbarService.setSnackbarSuccess(this.snackbar);
            this.visible = true;
          })
          .catch(() => {
            SnackbarService.setSnackbarError(this.snackbar);
            this.visible = true;
          });
      } else {
        UserService.disableNotification(date)
          .then(() => {
            this.getNotis();
            SnackbarService.setSnackbarSuccess(this.snackbar);
            this.visible = true;
          })
          .catch(() => {
            SnackbarService.setSnackbarError(this.snackbar);
            this.visible = true;
          });
      }
    },
    showwEvent(date) {
      if (this.disabledNotifications.includes(date)) {
        this.notification = i18n.tc("orders.enable");
        this.iconNoti = "mdi-check-circle-outline";
      } else {
        this.notification = i18n.tc("orders.disable");
        this.iconNoti = "mdi-close-circle-outline";
      }
      if (moment().isBefore(date)) {
        this.contextMenu = false;
        this.x = event.clientX;
        this.y = event.clientY;
        // make event usable by menuClick items
        this.clickedDate = date;
        this.$nextTick(() => {
          this.contextMenu = true;
        });
      }
    },

    //Checks if the date is valid, available
    isValid(date) {
      return !this.availableDays.includes(date);
    },

    //Checks if the date is today
    isToday(date) {
      return moment().format("YYYY-MM-DD") === date;
    },

    //Checks if the date is in the future to show the settings icon
    isValidSetting(date) {
      return moment().isBefore(date);
    },
    isNotWeekend(date) {
      return !(moment(date).day() === 0 || moment(date).day() === 6);
    },

    //Checks if the notification for the current date is disabled
    notificationDisabled(date) {
      return this.disabledNotifications.includes(date);
    },

    madeOrder() {
      this.getOrders();
      SnackbarService.setSnackbarSuccess(this.snackbar);
      this.visible = true; //Show snackbar
    },

    //When available date is clicked set values of the modal
    orderNew(date) {
      if (this.availableDays.includes(date)) {
        this.$refs.orderCalendar.form.delivery_date = [date];
        this.$refs.orderCalendar.date = date;
        this.$refs.orderCalendar.dialog = true;
      }
    },

    //Event when order is deleted
    deletedOrder() {
      this.getOrders();
      this.selectedOpen = false;
      SnackbarService.setSnackbarSuccess(this.snackbar);
      this.visible = true; //Show snackbar
      this.costs();
    },

    //Event when order is edited successfully
    updatedOrder(data) {
      this.getOrders();
      this.costs();
      this.selectedEvent.name = data.name;
      this.selectedEvent.note = data.note;
      this.selectedEvent.product_id = data.product_id;
      this.selectedOpen = false;
      SnackbarService.setSnackbarSuccess(this.snackbar);
      this.visible = true; //Show snackbar
    },

    //Event when order is rated successfully
    ratedOrder(data) {
      this.getOrders();
      this.selectedOpen = false;
      SnackbarService.setSnackbarSuccess(this.snackbar);
      this.visible = true;
      this.ratable = false;
      this.selectedOpen = false;
      this.selectedEvent.rating = data.rating;
      this.selectedEvent.rating_comment = data.rating_comment;
      this.selectedEvent.description = data.rating_comment;
    },

    //Calendar events
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    //Show clicked event
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.updatable = event.updatable;
        this.ratable = event.ratable;
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    //get all orders from the API and all available days
    getOrders() {
      this.overlay = true;
      UserService.getOrders().then(
        (response) => {
          this.events = response.data;
          this.overlay = false;
          this.costs();
        },
        () => {}
      );
    },

    //Get all notifications from API
    getNotis() {
      UserService.disabledNotifications().then((response) => {
        this.disabledNotifications = response.data;
      });
    },
    costs() {
      this.dailyCosts = [];
      for (let i in this.events) {
        this.monthlyCosts += this.events[i].price;
        this.dailyCosts.push({
          key: this.events[i].end,
          value: this.events[i].price,
        });
      }
    },
    costForDate(date = null) {
      if (date !== null) {
        for (let i in this.dailyCosts) {
          if (date === this.dailyCosts[i].key) {
            return this.dailyCosts[i].value + " €";
          }
        }
      }
      return "";
    },
  },

  //When component is mounted
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.user = this.$store.state.auth.user;
    // eslint-disable-next-line no-undef
    this.getOrders();
  },

  //Before the component is mounted
  beforeMount() {
    this.getNotis();
  },
};
