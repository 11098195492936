<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height class="side-img pa-0"> </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "SideImage",
};
</script>

<style scoped>
.side-img {
  background-image: url("https://i.imgur.com/jgOppK4.png");
  background-size: auto 110%;
  background-position: center;
}
</style>
