<template>
  <v-footer padless>
    <v-row justify="center" no-gutters>
      <v-divider />
      <v-col class="py-4 mt-0 text-center" cols="12">
        {{ new Date().getFullYear() }} —
        <a
          href="https://amplitudo.me"
          target="_blank"
          style="color: var(--primary-color)"
          ><strong>Amplitudo</strong></a
        >
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>
