<template>
  <v-card class="mx-auto my-3" max-width="344">
    <v-img height="180" :src="image">
      <v-chip v-if="forTomorrow" class="ma-2" x-small color="white">
        <v-icon left color="blue" style="font-size: 10px">
          mdi-checkbox-blank-circle</v-icon
        >
        {{ $t("for_tomorrow") }}
      </v-chip>
      <v-chip v-if="fromYesterday" class="ma-2" x-small color="white">
        <v-icon left color="red" style="font-size: 10px">
          mdi-checkbox-blank-circle</v-icon
        >
        {{ $t("yesterday") }}
      </v-chip>
    </v-img>

    <v-card-title
      style="
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      "
    >
      <router-link :to="'/product/' + id" class="black--text ma-0 pa-0">
        {{ name }}
        <div>
          <span style="color: #17ac9d"> {{ price }} €</span>
        </div>
      </router-link>
    </v-card-title>

    <v-card-text>
      <v-row align="center" class="mx-0">
        <!--        <router-link :to="'/reviews/' + id" class="reviews">-->
        <v-rating
          :value="rating"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating>
        <!--        </router-link>-->
        <div class="grey--text ms-4">{{ rating }} ({{ rating_count }})</div>
      </v-row>

      <div class="my-4"></div>

      <div class="ingredients">Sastojci: {{ ingredients }}</div>
    </v-card-text>

    <v-divider class="mx-4 mb-2"></v-divider>

    <v-card-actions class="mt-0 pt-0 justify-center">
      <v-btn
        color="var(--primary-color)"
        class="px-5"
        outlined
        text
        @click.prevent="$emit('clickOrder', product)"
      >
        {{ $t("home.order.order") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import OrderCard from "@/components/OrderCard";
import { BASE_URL } from "@/config";

export default {
  // components: { OrderCard },
  data: () => ({
    product: {
      id: null,
      name: null,
      image: null,
      price: null,
    },
    BASE_URL: BASE_URL,
  }),
  props: {
    name: {
      type: String,
    },
    image: {
      type: String,
    },
    rating: {
      type: Number,
    },
    price: {
      type: Number,
    },
    id: {
      type: Number,
    },
    ingredients: {
      type: String,
    },
    rating_count: {
      type: Number,
    },
    dates: {
      type: Array,
    },
    forTomorrow: {
      type: Boolean,
    },
    fromYesterday: {
      type: Boolean,
    },
  },
  mounted() {
    this.product.id = this.id;
    this.product.name = this.name;
    this.product.price = this.price;
    this.product.image = this.image;
  },
  methods: {
    onOrdered() {
      this.$emit("ordered");
    },
  },
};
</script>
<style scoped>
.ingredients {
  overflow: hidden;
  line-height: 1.5em;
  height: 3em;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
