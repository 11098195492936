import axios from "axios";
import { store } from "../store";
import { API_URL } from "../config";
// import i18n from "~/plugins/i18n";
// Request interceptor
axios.interceptors.request.use((request) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    request.headers.common.Authorization = `Bearer ${token}`;
  }
  return request;
});

// Response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (window.location.pathname !== "/login") {
      if (status === 401 && store.getters["auth/check"]) {
        if (localStorage.getItem("remember_me") === "true") {
          axios
            .post(
              API_URL + "refresh",
              {},
              {
                headers: {
                  Authorization:
                    "Bearer " +
                    localStorage
                      .getItem("access_token")
                      .substr(
                        1,
                        localStorage.getItem("access_token").length - 2
                      ),
                },
              }
            )
            .then((response) => {
              localStorage.setItem(
                "access_token",
                JSON.stringify(response.data.access_token)
              );
              window.location.reload();
            })
            .catch(() => {});
        } else {
          store.dispatch("auth/logout");
          window.location.pathname = "/login";
        }
      }
    }

    return Promise.reject(error);
  }
);
