<template>
  <v-dialog v-model="dialogRate" persistent max-width="290">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        @click.prevent="changeData(selectedEvent)"
        icon
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    <v-card class="elevation-16 mx-auto" width="300">
      <v-card-title class="text-h5">
        {{ $t("orders.delete.title") }}
      </v-card-title>
      <v-card-text class="nb-0 pb-0">
        {{ $t("orders.delete.warning") }}

        <div class="text-center mt-2">
          <form
            @submit.prevent="deleteOrder"
            @keydown="formDelete.onKeydown($event)"
          ></form>
        </div>
        <Snackbar
          v-if="visible"
          :form="formDelete"
          :timeout="timeout"
          :snackbar="snackbar"
          :error_field="formDelete.errors.get('rating')"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-space-between">
        <v-btn text @click="dialogRate = false">
          {{ $t("orders.delete.give_up") }}
        </v-btn>
        <v-btn
          text
          outlined
          color="red"
          @click.prevent="deleteOrder"
          :disabled="processingOrder"
        >
          {{ $t("orders.delete.delete") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Snackbar from "@/components/Snackbar";
import { API_URL } from "@/config";
import authHeader from "@/services/auth-header";
import Form from "vform";
import moment from "moment";

export default {
  name: "DeleteDialog",
  components: { Snackbar },
  data() {
    return {
      snackbar: {
        color: "",
        icon: "",
        title: "",
      },
      formDelete: new Form({
        order_id: null,
      }),
      processingOrder: false,
      dialogRate: false,
      visible: false,
      timeout: 1200,
    };
  },
  props: {
    getOrders: Function,
    selectedEvent: Object,
  },
  methods: {
    deletedOrder(form) {
      let data = { order_id: form.order };
      this.$emit("deleted", data);
    },
    changeData(event) {
      this.formDelete.order_id = event.id;
    },
    async deleteOrder() {
      this.processingOrder = true;
      await this.formDelete
        .delete(API_URL + `order/${this.selectedEvent.id}`, {
          headers: authHeader(),
        })
        .then(() => {
          let day = (
            moment().weekday() == 5
              ? moment().add(3, "days")
              : moment().weekday() == 6
              ? moment().add(2, "days")
              : moment().add(1, "days")
          ).format("YYYY-MM-DD");
          if (this.selectedEvent.start === day) {
            this.$store.commit("orderedForTomorrow", false);
          }
          this.deletedOrder(this.formDelete);
          this.processingOrder = false;
          this.dialogRate = false;
        })
        .catch(() => {
          this.snackbar.icon = "mdi-alert-circle";
          this.snackbar.title = this.$t("snackbar.error");
          this.snackbar.color = "red";
          this.processingOrder = false;
          this.visible = true;
        });
    },
  },
};
</script>

<style scoped></style>
