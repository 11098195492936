export const ordered = {
  state: {
    orderedForTomorrow: false,
  },
  mutations: {
    orderedForTomorrow(state, payload) {
      state.orderedForTomorrow = payload;
    },
  },
  actions: {},
  getters: {
    orderedForTomorrow: (state) => {
      return state.orderedForTomorrow;
    },
  },
};
