<template>
  <div>
    <navbar />
    <h2
      v-html="$t('profile.title', { first_name: currentUser.first_name })"
      class="text-center pt-16"
    ></h2>
    <v-row>
      <v-col
        cols="11"
        lg="5"
        md="8"
        class="text-center container-main mt-12 mx-auto rounded shadow-sm"
      >
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form
            @submit.prevent="submit"
            class="pa-sm-6 pa-lg-12 pa-md-12 pb-6 px-lg-16 px-md-16 px-sm-8"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('profile.first_name')"
              rules="required|max:20"
            >
              <v-text-field
                v-model="form.first_name"
                :error-messages="errors"
                :label="$t('profile.first_name') + '*'"
                required
              ></v-text-field>
              <p
                class="red--text text-left text-caption"
                text-align="left"
                v-if="form.errors.has('first_name')"
              >
                {{ form.errors.get("first_name") }}
              </p>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              :name="$t('profile.last_name')"
              rules="required|max:20"
            >
              <v-text-field
                v-model="form.last_name"
                :error-messages="errors"
                :label="$t('profile.last_name') + '*'"
                required
              ></v-text-field>
              <p
                class="red--text text-left text-caption"
                text-align="left"
                v-if="form.errors.has('last_name')"
              >
                {{ form.errors.get("last_name") }}
              </p>
            </validation-provider>
            <v-text-field
              v-model="email"
              :label="$t('profile.email')"
              disabled
              required
            ></v-text-field>
            <validation-provider name="office" rules="required">
              <v-select
                v-model="form.office_id"
                :items="offices"
                :label="$t('profile.office')"
                item-text="name"
                item-value="id"
                required
              ></v-select>
            </validation-provider>
            <v-switch
              v-model="form.enable_notification"
              inset
              color="var(--primary-color)"
              :label="$t('profile.notifications')"
              class="notifications"
              hide-details
            ></v-switch>

            <v-btn
              class="mr-4 px-10 mt-6 white--text"
              type="submit"
              color="var(--primary-color)"
              @click="submit"
              :disabled="updatingProfile || loadingOffices || invalid"
            >
              <circle-svg v-if="updatingProfile" class="w-6 h-6 mr-2" />
              {{ $t("profile.save") }}
            </v-btn>
          </form>
        </validation-observer>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="visible"
      :color="snackbar.color"
      multi-line
      :timeout="timeout"
      top
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
        <v-layout column>
          <div>
            <strong>{{ snackbar.title }}</strong>
          </div>
          <div>{{ form.errors.get("office_id") }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </div>
</template>

<script>
import { required, max } from "vee-validate/dist/rules";
import UserService from "@/services/user.service";
import CircleSvg from "@/components/CircleSvg";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import Navbar from "@/components/Navbar";
import Form from "vform";
import { API_URL } from "@/config";
import authHeader from "@/services/auth-header";

setInteractionMode("eager");
import i18n from "../plugins/i18n";

extend("required", {
  ...required,
  message: "{_field_} " + i18n.tc("profile.can_not_be_empty"),
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

export default {
  name: "Profile",
  components: {
    Navbar,
    CircleSvg,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    name: "",
    visible: false,
    snackbar: {
      color: "",
      icon: "",
      title: "",
    },
    timeout: 1200,
    phoneNumber: "",
    email: "",
    offices: [],
    checkbox: null,
    user: null,
    form: new Form({
      first_name: "",
      last_name: "",
      office_id: null,
      enable_notification: 0,
    }),
    updatingProfile: false,
    loadingOffices: true,
    switch1: true,
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    async submit() {
      this.updatingProfile = true;
      this.$refs.observer.validate();
      if (
        this.form.enable_notification === 0 ||
        this.form.enable_notification === 1
      ) {
        this.form.enable_notification =
          this.form.enable_notification === true ? 1 : 0;
      }
      try {
        await this.form.put(API_URL + `user`, {
          headers: authHeader(),
        });
        this.snackbar.icon = "mdi-check-circle";
        this.snackbar.title = this.$t("snackbar.success");
        this.snackbar.color = "success";
        this.updatingProfile = false;
        this.visible = true;
        UserService.getMe().then((response) => {
          localStorage.setItem("user", JSON.stringify(response.data));
          this.$store.commit("auth/loginSuccess", { user: response.data });
        });
      } catch (error) {
        this.snackbar.icon = "mdi-alert-circle";
        this.snackbar.title = this.$t("snackbar.error");
        this.snackbar.color = "red";
        this.updatingProfile = false;
        this.visible = true;
      }
    },
    clear() {
      this.name = "";
      this.phoneNumber = "";
      this.email = "";
      this.select = null;
      this.checkbox = null;
    },
  },

  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.loadingOffices = true;
    this.user = this.$store.state.auth.user;
    this.form.first_name = this.currentUser.first_name;
    this.form.last_name = this.currentUser.last_name;
    this.form.enable_notification = this.currentUser.enable_notification;
    this.email = this.currentUser.email;
    UserService.getOffices().then((response) => {
      this.offices = response.data.data;
      this.form.office_id = this.user.office_id;
      this.loadingOffices = false;
    });
  },
};
</script>
<style>
.container-main {
  background-color: #f8f8f8 !important;
}

.v-input--hide-details > .v-input__control > .v-input__slot > .v-label {
  margin-bottom: 0 !important;
}
</style>
