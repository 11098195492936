import HomeCard from "@/components/HomeCard";
import Navbar from "@/components/Navbar";
import moment from "moment";
import CantOrder from "../../components/CantOrder.vue";
import { BASE_URL } from "../../config";

export default {
  name: "Home",
  components: { CantOrder, Navbar, HomeCard },
  data() {
    return {
      model: false,
      product: {
        id: null,
        name: null,
        image: BASE_URL + "/images/image_coming_soon.png",
        dates: null,
        ingredients: null,
        dialog: true,
      },
      user: null,
      loading: true,
      weekend: true,
      currentUser: this.$store.state.auth.user,
      visible: false,
      timeout: 1200,
      data: {
        mealTypes: ["Doručak", "Ručak"],
        suppliers: ["La Parisienne", "Domestic kitchen"],
        images: [
          BASE_URL + "/images/supplier-images/la_parisienne.jpg",
          BASE_URL + "/images/supplier-images/domestic_kitchen.jpeg",
        ],
      },
    };
  },
  methods: {},
  beforeMount() {
    this.weekend = moment().weekday() === 6 || moment().weekday() === 0;
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.currentUser = this.$store.state.auth.user;
    this.loading = false;
  },
};
