<template>
  <v-snackbar
    v-model="visible"
    :color="snackbar.color"
    multi-line
    :timeout="timeout"
    top
  >
    <v-layout align-center pr-4>
      <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
      <v-layout column>
        <div>
          <strong>{{ snackbar.title }}</strong>
        </div>
        <div>{{ form.errors.get(error_field) }}</div>
      </v-layout>
    </v-layout>
  </v-snackbar>
</template>

<script>
export default {
  name: "Snackbar",
  data() {
    return {
      visible: true,
    };
  },
  props: {
    color: {
      type: String,
    },
    icon: {
      type: String,
    },
    form: {
      type: Object,
    },
    title: {
      type: String,
    },
    snackbar: {
      type: Object,
    },
    timeout: {
      type: Number,
    },
    error_field: {
      type: String,
    },
  },
};
</script>

<style scoped></style>
