import FoodCard from "@/components/FoodCard";
import UserService from "../../services/user.service";
import SnackbarService from "../../services/snackbar.service";
import Navbar from "@/components/Navbar";
import moment from "moment";
import CantOrder from "../../components/CantOrder.vue";
import OrderCard from "@/components/OrderCard/OrderCard";
import RadioButtonComponent from "../../components/RadioButtonComponent.vue";

export default {
  name: "Home",
  components: {
    RadioButtonComponent,
    CantOrder,
    Navbar,
    FoodCard,
    OrderCard,
  },
  data() {
    return {
      model: false,
      product: {
        id: null,
        name: null,
        image: null,
        dates: null,
        ingredients: null,
        dialog: true,
      },
      radioGroup: [
        { label: "Sve", value: 0 },
        { label: "Ponedjeljak", value: 1 },
        { label: "Utorak", value: 2 },
        { label: "Srijeda", value: 3 },
        { label: "Četvrtak", value: 4 },
        { label: "Petak", value: 5 },
      ],
      user: null,
      mealType: 1,
      products: [],
      orderedDates: [],
      ordered: false,
      loading: true,
      weekend: true,
      currentUser: this.$store.state.auth.user,
      visible: false,
      timeout: 1200,
      snackbar: {
        icon: "",
        title: "",
        color: "",
      },
    };
  },
  methods: {
    getData(day = null) {
      this.loading = true;
      if (this.weekend === false) {
        UserService.getProductsByMealType(this.mealType, day).then(
          (response) => {
            this.products = response.data.data;
            this.loading = false;
            console.log(this.products.length);
          }
        );
      }
    },
    onRadioChanged(event) {
      this.getData(event.target._value);
    },
    changeFlag(pid) {
      let ind = this.products.find((el) => el.id === pid);
      ind.for_tomorrow = true;
    },
    makeOrder(data) {
      this.loading = true;
      this.product.id = data.id;
      this.product.name = data.name;
      this.product.image = data.image;
      this.product.user_price = data.user_price;
      this.product.ingredients = data.ingredients;
      this.$refs.orderCard.dialog = true;
      this.$refs.orderCard.form.product_id = data.id;
      this.$emit("clickOrder", data);
      UserService.getAvailableDays(data.id).then(
        (response) => {
          this.product.dates = response.data;
        },
        () => {}
      );
      this.loading = false;
    },
    getAvailableDays() {
      UserService.getAvailableDays().then((response) => {
        this.product.dates = response.data;
      });
    },
    onOrdered() {
      SnackbarService.setSnackbarSuccess(this.snackbar);
      this.visible = true;
    },
    parseIngredients(ingredients) {
      let ingredients_arr = [];
      ingredients.forEach(async function (ingredient) {
        ingredients_arr.push(ingredient.name);
      });
      return ingredients_arr.join(", ");
    },
  },
  beforeMount() {
    this.weekend = moment().weekday() === 6 || moment().weekday() === 0;
  },
  mounted() {
    if (this.$route.name === "lunch") {
      this.mealType = 2;
    }

    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.currentUser = this.$store.state.auth.user;
    this.getData(0);
    UserService.orderedForFuture().then((response) => {
      this.orderedDates = response.data.data;
      this.loading = false;
    });
  },
};
