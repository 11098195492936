import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";
import { ordered } from "@/store/ordered.module";
import { locales } from "./locales.module";

Vue.use(Vuex);
export const store = new Vuex.Store({
  modules: {
    auth,
    ordered,
    locales,
  },
});
