<template>
  <v-dialog v-model="dialogRate" persistent max-width="340">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        @click.prevent="changeData(selectedEvent)"
        icon
      >
        <v-icon>mdi-star</v-icon>
      </v-btn>
    </template>
    <v-card class="elevation-16 mx-auto" max-width="340">
      <v-card-title class="text-h5"> {{ $t("rate.title") }} </v-card-title>
      <v-card-text class="text-center">
        {{ $t("rate.subtitle") }}

        <div class="text-center justify-center align-center align-self-center">
          <v-form
            v-model="valid"
            @submit.prevent="rateOrder"
            @keydown="formRating.onKeydown($event)"
          >
            <v-input
              hide-details
              v-model="formRating.rating"
              :rules="rules.required"
            >
              <v-rating
                :rules="[rules.required]"
                v-model="formRating.rating"
                color="yellow darken-3"
                background-color="grey darken-1"
                empty-icon="$ratingFull"
                half-increments
                hover
              ></v-rating>
            </v-input>
            <has-error :form="formRating" field="rating" />
            <v-textarea
              no-resize
              outlined
              rows="3"
              class="mt-2"
              name="input-7-4"
              :rules="[rules.lengthMax(100)]"
              counter="100"
              v-model="formRating.description"
              :label="$t('rate.comment')"
            ></v-textarea>
            <has-error :form="formRating" field="description" />
          </v-form>
        </div>
        <Snackbar
          v-if="visible"
          :form="formRating"
          :timeout="timeout"
          :snackbar="snackbar"
          :error_field="formRating.errors.get('rating')"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-space-between">
        <v-btn text @click="closeDialog">
          {{ $t("rate.no_thanks") }}
        </v-btn>
        <v-btn
          color="var(--primary-color)"
          @click.prevent="rateOrder"
          :disabled="!valid || processingOrder"
          class="white--text"
        >
          <circle-svg v-if="processingOrder" class="w-6 h-6 mr-2" />
          {{ $t("rate.rate_now") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Snackbar from "@/components/Snackbar";
import { API_URL } from "@/config";
import authHeader from "@/services/auth-header";
import Form from "vform";
import HasError from "@/components/HasError";
import CircleSvg from "@/components/CircleSvg";

export default {
  name: "RateDialog",
  components: { Snackbar, HasError, CircleSvg },
  data() {
    return {
      rules: {
        required: [(v) => !!v || "Obavezno polje!"],
        lengthMin: (len) => (v) =>
          (v || "").length >= len || `Minimalan broj karaktera je ${len}.`,
        lengthMax: (len) => (v) =>
          (v || "").length <= len || `Maksimalan broj karaktera je ${len}.`,
      },
      snackbar: {
        color: "",
        icon: "",
        title: "",
      },
      formRating: new Form({
        description: "",
        rating: null,
        order_id: null,
      }),
      processingOrder: false,
      dialogRate: false,
      visible: false,
      timeout: 1200,
      valid: false,
    };
  },
  props: {
    getOrders: Function,
    selectedEvent: Object,
  },
  methods: {
    closeDialog() {
      this.dialogRate = false;
      this.formRating.reset();
    },
    ratedOrder(form) {
      let data = { rating: form.rating, rating_comment: form.description };
      this.$emit("rated", data);
    },
    changeData(event) {
      this.formRating.order_id = event.id;
    },
    async rateOrder() {
      this.processingOrder = true;
      await this.formRating
        .post(API_URL + "rate", {
          headers: authHeader(),
        })
        .then(() => {
          this.processingOrder = false;
          this.ratedOrder(this.formRating);
          // this.snackbar.icon = "mdi-check-circle";
          // this.snackbar.title = this.$t("snackbar.success");
          // this.snackbar.color = "var(--primary-color)";
          // this.visible = true;
          // this.getOrders();
          // setTimeout(() => {
          //   this.dialogRate = false;
          //   this.ratedOrder(this.formRating);
          //   this.formRating.reset();
          // }, 1200);
        })
        .catch(() => {
          this.snackbar.icon = "mdi-alert-circle";
          this.snackbar.title = this.$t("snackbar.error");
          this.snackbar.color = "red";
          this.processingOrder = false;
          this.visible = true;
        });
    },
  },
};
</script>

<style scoped></style>
