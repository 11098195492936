<template>
  <div class="sticky-top" style="z-index: 12">
    <nav
      class="navbar navbar-expand-lg navbar-dark shadow-sm bg-light"
      style="z-index: 2"
    >
      <div class="container p-2">
        <router-link
          class="navbar-brand d-flex text--white align-items-center"
          to="/home"
        >
          <img
            src="@/assets/images/SVG/icon.svg"
            class="d-lg-block d-md-block"
            height="48"
            alt=""
          />
          <span class="ml-3 font-weight-bold font-main logotext">{{
            $t("app_name")
          }}</span>
        </router-link>
        <button
          class="navbar-toggler navbar-toggler-right border-0"
          type="button"
          data-toggle="collapse"
          data-target="#navbar4"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbar4">
          <ul class="navbar-nav ml-auto pl-lg-4">
            <li class="nav-item px-lg-2">
              <router-link class="nav-link" to="/home">
                <span class="d-inline-block mr-2 icon-width">
                  <i class="fas fa-home"></i></span
                >{{ $t("navbar.home") }}</router-link
              >
            </li>
            <li class="nav-item px-lg-2">
              <router-link class="nav-link" to="/orders">
                <span class="d-inline-block mr-2 icon-width">
                  <i class="fas fa-spa"></i></span
                >{{ $t("navbar.orders") }}</router-link
              >
            </li>
            <li
              class="nav-item dropdown px-lg-2"
              :class="
                $route.path == '/profile'
                  ? 'active'
                  : '' || $route.path == '/change-password'
                  ? 'active'
                  : '' || $route.path == '/stats'
                  ? 'active'
                  : ''
              "
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas mr-2 fa-user-alt"></i> {{ $t("navbar.profile") }}
              </a>
              <div
                class="dropdown-menu"
                style="z-index: 100"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <router-link to="/profile" class="dropdown-item">{{
                  $t("navbar.my_profile")
                }}</router-link>
                <router-link to="/change-password" class="dropdown-item">{{
                  $t("navbar.change_password")
                }}</router-link>
                <router-link to="/stats" class="dropdown-item">{{
                  $t("navbar.stats")
                }}</router-link>
              </div>
            </li>
            <li class="nav-item px-lg-2">
              <a class="nav-link" @click.prevent="logOut"
                ><span class="d-inline-block mr-2 icon-width"
                  ><i class="fas fa-sign-out-alt"></i></span
                >{{ $t("navbar.logout") }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <v-banner
      v-if="
        !orderedForTomorrow &&
        $route.path !== '/change-password' &&
        $route.path !== '/profile' &&
        $route.path !== '/orders' &&
        $route.name !== 'product' &&
        $route.path !== '/stats'
      "
      class="backdrop"
      icon="mdi-alert-circle"
      icon-color="white darken-4"
      single-line
    >
      {{ $t("navbar.havent_ordered") }}
    </v-banner>

    <v-banner
      v-model="v0"
      v-if="
        orderedForTomorrow &&
        $route.path !== '/change-password' &&
        $route.path !== '/profile' &&
        $route.path !== '/orders' &&
        $route.name !== 'product' &&
        $route.path !== '/stats'
      "
      single-line
      class="backdrop2 bottomtext"
      transition="slide-y-transition"
    >
      {{ $t("navbar.already_ordered") }}
      <span v-if="count > 2"
        >{{ $t("navbar.and") }} {{ count - 1 }}
        {{ $t("navbar.more_days") }}</span
      >. {{ $t("navbar.check_order") }}
      <router-link to="/orders">{{ $t("navbar.orders") }}</router-link>
      <template v-slot:actions="{ dismiss }">
        <v-btn text color="#ffcd9a" @click="dismiss">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-banner>
  </div>
</template>

<script>
import UserService from "@/services/user.service";
import { mapState } from "vuex";
import { languages } from "../plugins/i18n";

export default {
  name: "Navbar",
  data: () => ({
    nav_items: [
      { name: "Home", route: "/home", icon: "fas fa-home" },
      { name: "Orders", route: "/orders", icon: "fas fa-spa" },
      { name: "Profile", route: "/profile", icon: "fas fa-user" },
    ],
    count: null,
    v0: true,
    oft: false,
    languageArray: languages,
    langs: [
      {
        id: "en",
        name: "English",
        flag: "em-flag-us",
      },
      {
        id: "sr",
        name: "Crnogorski",
        flag: "em-flag-me",
      },
    ],
  }),
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    UserService.hasOrderedForTomorrow().then((response) => {
      this.$store.commit("orderedForTomorrow", !!response.data.ordered);
      this.oft = !!response.data.ordered;
      this.count = response.data.count;
    });
  },
  computed: {
    ...mapState({
      orderedForTomorrow: (state) => state.ordered.orderedForTomorrow,
    }),
    lang: {
      get: function () {
        return this.$store.state.locales.locale;
      },
      set: function (newVal) {
        this.$store.dispatch("changeLocale", newVal);
      },
    },
  },
};
</script>

<style scoped>
@import "https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css";
.navbar {
  color: white !important;
  background-color: rgba(23, 172, 157, 1) !important;
}
.backdrop {
  color: white !important;
  background-color: rgba(231, 64, 64, 1) !important;
}
.backdrop2 {
  color: white !important;
  background-color: rgb(22 142 130) !important;
}
.dropdown-menu .active {
  background-color: var(--primary-color) !important;
}

.dropdown-menu a.active {
  color: white !important;
}

.dropdown-menu a {
  color: var(--primary-color) !important;
}
.backdrop2 a {
  color: #ffcd9a !important;
}
nav {
  padding: 0;
}
select {
  color: #ffcd9a !important;
}
@media screen and (max-width: 480px) {
  .logotext {
    font-size: 1rem;
  }
  .bottomtext {
    font-size: 0.7rem;
  }
}
.v-banner__wrapper {
  padding: 0;
}
</style>
