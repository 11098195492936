<template>
  <div>
    <navbar />
    <h2
      class="text-center pt-16"
      v-html="
        $t('profile.change_password.title', {
          first_name: currentUser.first_name,
        })
      "
    ></h2>
    <v-row>
      <v-col
        cols="11"
        lg="5"
        md="8"
        class="text-center container-main mt-12 mx-auto rounded shadow-sm"
      >
        <v-form
          @submit.prevent="submit"
          ref="form"
          v-model="valid"
          class="pa-sm-6 pa-lg-12 pa-md-12 pb-6 px-lg-16 px-md-16 px-sm-8"
        >
          <v-text-field
            tabindex="1"
            v-model="form.old_password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passwordRules"
            :type="show ? 'text' : 'password'"
            :label="$t('profile.change_password.old_password') + '*'"
            prepend-icon="mdi-lock-clock"
            @click:append="show = !show"
          ></v-text-field>
          <p
            class="red--text text-left text-caption"
            text-align="left"
            v-if="form.errors.has('old_password')"
          >
            {{ form.errors.get("old_password") }}
          </p>

          <v-text-field
            tabindex="1"
            v-model="form.password"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passwordNewRules"
            :type="show2 ? 'text' : 'password'"
            :label="$t('profile.change_password.new_password') + '*'"
            prepend-icon="mdi-lock"
            @click:append="show2 = !show2"
          ></v-text-field>

          <v-text-field
            tabindex="1"
            v-model="form.password_confirmation"
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passwordNewRules"
            :type="show3 ? 'text' : 'password'"
            :label="$t('profile.change_password.confirm_new_password') + '*'"
            prepend-icon="mdi-lock"
            @click:append="show3 = !show3"
          ></v-text-field>
          <p
            class="red--text text-left text-caption"
            text-align="left"
            v-if="form.errors.has('password')"
          >
            {{ form.errors.get("password") }}
          </p>
          <v-btn
            tabindex="1"
            class="mr-4 px-10 mt-3 white--text"
            type="submit"
            color="var(--primary-color)"
            @click="submit"
            :disabled="!valid || updatingProfile"
          >
            <circle-svg v-if="updatingProfile" class="w-6 h-6 mr-2" />
            {{ $t("profile.save") }}
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="visible"
      :color="snackbar.color"
      multi-line
      :timeout="timeout"
      top
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
        <v-layout column>
          <div>
            <strong>{{ snackbar.title }}</strong>
          </div>
          <div>{{ form.errors.get("password") }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </div>
</template>

<script>
import UserService from "@/services/user.service";
import CircleSvg from "@/components/CircleSvg";
import Navbar from "@/components/Navbar";
import Form from "vform";
import { API_URL } from "@/config";
import authHeader from "@/services/auth-header";
import i18n from "../plugins/i18n";

export default {
  name: "Profile",
  components: {
    Navbar,
    CircleSvg,
  },
  data: () => ({
    loading: false,
    message: "",
    errors: null,
    valid: true,
    passwordRules: [(v) => !!v || i18n.tc("auth.password_required")],
    passwordNewRules: [
      (v) =>
        (v && v.length >= 8) ||
        i18n.tc("profile.change_password.password_validation_len"),
    ],
    show: false,
    show2: false,
    show3: false,
    name: "",
    visible: false,
    snackbar: {
      color: "",
      icon: "",
      title: "",
    },
    timeout: 1200,
    phoneNumber: "",
    email: "",
    offices: [],
    checkbox: null,
    user: null,
    form: new Form({
      old_password: "",
      password: "",
      password_confirmation: "",
    }),
    updatingProfile: false,
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    async submit() {
      this.updatingProfile = true;
      await this.form
        .post(API_URL + "change-password", {
          headers: authHeader(),
        })
        .then(() => {
          this.snackbar.icon = "mdi-check-circle";
          this.snackbar.title = this.$t("snackbar.success");
          this.snackbar.color = "green";
          this.updatingProfile = false;
          this.visible = true;
          // this.form.reset();
          this.$refs.form.reset();
          this.form.errors.clear();
        })
        .catch(() => {
          this.snackbar.icon = "mdi-alert-circle";
          this.snackbar.title = this.$t("snackbar.error");
          this.snackbar.color = "red";
          this.updatingProfile = false;
          this.visible = true;
        });
    },
    clear() {
      this.name = "";
      this.phoneNumber = "";
      this.email = "";
      this.select = null;
      this.checkbox = null;
    },
  },

  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.user = this.$store.state.auth.user;
    this.form.first_name = this.user.first_name;
    this.form.last_name = this.user.last_name;
    this.email = this.user.email;
    UserService.getOffices().then((response) => {
      this.offices = response.data.data;
      this.form.office_id = this.user.office_id;
    });
  },
};
</script>
<style scoped>
.container-main {
  background-color: #f8f8f8 !important;
}
</style>
