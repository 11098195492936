<template>
  <div
    class="
      radio-button-group radio-button-group
      px-lg-16 px-11 px-md-8 px-sm-0
      row
      wrap
      justify-center
    "
  >
    <div
      v-for="(option, index) in options"
      :key="index"
      class="radio-button-group__option"
    >
      <div class="radio-button-group__button">
        <input
          type="radio"
          :id="option.value"
          :value="option.value"
          v-model="selectedOption"
          @change="$emit('radio-changed', $event)"
          :checked="option.value === selectedOption"
        />
        <label :for="option.value">{{ option.label }}</label>
      </div>
    </div>
  </div>
</template>

<style scoped>
.radio-button-group {
  display: flex;
}

.radio-button-group__option {
  margin-right: 1rem;
  padding: 3px;
}

.radio-button-group__button {
  display: flex;
  align-items: center;
}

.radio-button-group__button input[type="radio"] {
  display: none;
  margin: 0;
}

.radio-button-group__button label {
  margin: 0;
  padding: 0.5rem;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #17ac9d;
  border-radius: 4px;
  cursor: pointer;
}

.radio-button-group__button input[type="radio"]:checked + label {
  background-color: #17ac9d;
  color: #fff;
}
</style>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedOption: 0,
    };
  },
};
</script>
