<template>
  <div>
    <Navbar></Navbar>
    <div class="pa-4 pt-12">
      <h2 class="text-center">Recenzije za proizvod: {{ productName }}</h2>
      <v-card max-width="450" class="mx-auto mt-6">
        <div v-if="!hasReviews && !loading">
          <img src="@/assets/images/coffee.svg" alt="Nema recenzija" />
          <h2 class="text-center">Nema recenzija</h2>
        </div>
        <div v-for="i in 5" :key="i">
          <v-skeleton-loader
            v-if="loading"
            type="list-item-avatar, list-item-two-line"
          ></v-skeleton-loader>
        </div>
        <v-list three-line v-if="!loading">
          <template v-for="(item, index) in reviews.data">
            <v-list-item :key="item.user.comment">
              <v-list-item-avatar>
                <v-img :src="item.user.image"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <v-rating
                    :value="item.rating"
                    background-color="var(--primary-color)"
                    color="amber"
                    dense
                    half-increments
                    small
                    readonly
                  ></v-rating>
                  <v-chip class="ma-2" x-small chip>
                    <v-icon left> mdi-calendar </v-icon>
                    {{ item.date }}
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span class="text--primary"
                    >{{ item.user.name
                    }}<span v-if="item.comment !== null">:</span>
                  </span>
                  {{ item.comment }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              :key="index"
              v-if="reviews.length !== 0 && index !== reviews.data.length - 1"
            ></v-divider>
          </template>
        </v-list>
      </v-card>

      <pagination
        class="justify-center mt-4"
        :data="reviews"
        @pagination-change-page="getResults"
      >
      </pagination>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import UserService from "@/services/user.service";
export default {
  name: "Reviews",
  components: { Navbar },
  data: () => ({
    reviews: {},
    hasReviews: false,
    currentUser: [],
    loading: true,
    productName: "",
  }),
  methods: {
    // Our method to GET results from a Laravel endpoint

    getResults(page = 1) {
      this.loading = true;
      UserService.getReviews(this.$route.params.id, page).then(
        (response) => {
          this.reviews = response.data;
          this.productName = response.data.productName;
          if (response.data.data.length > 0) this.hasReviews = true;
          this.loading = false;
        },
        () => {}
      );
    },
  },
  mounted() {
    this.currentUser = this.$store.state.auth.user;
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.getResults();
  },
};
</script>

<style scoped></style>
