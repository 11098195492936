import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home/Home.vue";
import Products from "../views/Products/Products.vue";
import Login from "../views/Welcome.vue";
import Orders from "../views/Orders/Orders.vue";
import Profile from "@/views/Profile";
import ChangePassword from "@/views/ChangePassword";
import Err404 from "@/views/Err404";
import Reviews from "@/views/Reviews";
import Product from "@/views/Product";
import Stats from "@/views/Stats";
// import Register from './views/Register.vue';

Vue.use(Router);

export const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "home",
      component: Login,
    },
    {
      path: "/home",
      component: Home,
    },
    {
      path: "/breakfast",
      name: "breakfast",
      component: Products,
    },
    {
      path: "/lunch",
      name: "lunch",
      component: Products,
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/orders",
      component: Orders,
    },
    {
      path: "/profile",
      component: Profile,
    },
    {
      path: "/change-password",
      component: ChangePassword,
    },
    {
      path: "/stats",
      component: Stats,
    },
    {
      path: "/404",
      component: Err404,
    },
    {
      path: "/reviews/:id",
      component: Reviews,
    },
    {
      path: "/product/:id",
      component: Product,
      name: "product",
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/404",
    },
  ],
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });
