<template>
  <div>
    <navbar></navbar>
    <v-container class="mt-12 w-75 elevation-3 rounded pa-4">
      <v-skeleton-loader
        max-width="750"
        type="card"
        v-if="loading"
      ></v-skeleton-loader>
      <v-row v-if="!loading">
        <v-col class="col-12 col-lg-6 col-md-12">
          <v-img
            height="100%"
            lazy-src="@/assets/images/foodph.png"
            max-width="850"
            :src="BASE_URL + product.image"
          ></v-img>
        </v-col>
        <v-col class="col-12 col-lg-6 col-md-12">
          <div>
            <h4
              class="product-title align-self-start font-weight-bold"
              style="display: inline; float: left"
            >
              {{ product.name }}
            </h4>
            <h5 style="color: #17ac9d; display: inline; float: right">
              {{ product.price }} €
            </h5>
          </div>
          <div class="rating flex pl-3">
            <v-rating
              :value="product.rating"
              color="amber"
              dense
              half-increments
              readonly
              size="14"
            ></v-rating>
            <div class="grey--text ms-4">
              {{ product.rating }} ({{ product.ratings }})
            </div>
          </div>
          <v-divider class="mt-5"></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Masa</v-list-item-title>
              <v-list-item-subtitle>{{ product.weight }}g</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Alergeni</v-list-item-title>
              <v-list-item-subtitle
                >{{ product.allergens === null ? "/" : product.allergens }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Sastojci</v-list-item-title>
              <v-list-item-subtitle
                >{{ product.parsed_ingredients }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Dostavljač</v-list-item-title>
              <v-list-item-subtitle
                >{{ product.supplier }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-12 mb-12 w-75">
      <v-row class="">
        <v-col class="elevation-2 col-12 col-lg-6 rounded">
          <v-card class="elevation-0 w-100">
            <div class="header">
              <p>Recenzije</p>
            </div>
            <div v-if="!hasReviews && !loadingRev">
              <h4 class="text-center pa-4">Nema recenzija</h4>
            </div>
            <div v-for="i in 5" :key="i">
              <v-skeleton-loader
                v-if="loadingRev"
                type="list-item-avatar, list-item-two-line"
              ></v-skeleton-loader>
            </div>
            <v-list three-line v-if="!loadingRev">
              <template v-for="(item, index) in reviews.data">
                <v-list-item :key="item.user.comment" class="pr-0">
                  <v-list-item-avatar>
                    <v-img :src="item.user.image"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      <v-rating
                        :value="item.rating"
                        background-color="var(--primary-color)"
                        color="amber"
                        dense
                        half-increments
                        small
                        readonly
                      ></v-rating>
                      <v-chip
                        class="ma-2"
                        x-small
                        chip
                        style="margin-left: 0 !important"
                      >
                        <v-icon left> mdi-calendar</v-icon>
                        {{ item.date }}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle style="-webkit-line-clamp: inherit">
                      <span class="text--primary"
                        >{{ item.user.name
                        }}<span v-if="item.comment !== null">:</span>
                      </span>
                      {{ item.comment }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  :key="index"
                  v-if="
                    reviews.length !== 0 && index !== reviews.data.length - 1
                  "
                ></v-divider>
              </template>
            </v-list>
          </v-card>
          <pagination
            class="justify-center mt-4"
            :data="reviews"
            @pagination-change-page="getResults"
          >
          </pagination>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import UserService from "@/services/user.service";
import { BASE_URL } from "@/config";

export default {
  name: "Product",
  components: { Navbar },
  data: () => ({
    product: {},
    tab: null,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    hasReviews: false,
    reviews: {},
    currentUser: [],
    BASE_URL: BASE_URL,
    loading: true,
    loadingRev: true,
    productName: "",
    price: 0,
  }),
  methods: {
    getResults(page = 1) {
      this.loadingRev = true;
      UserService.getReviews(this.$route.params.id, page).then(
        (response) => {
          this.reviews = response.data;
          this.productName = response.data.productName;
          this.price = response.data.user_price;
          if (response.data.data.length > 0) this.hasReviews = true;
          this.loadingRev = false;
        },
        () => {}
      );
    },
    parseIngredients(ingredients) {
      let ingredients_arr = [];
      ingredients.forEach(async function (ingredient) {
        ingredients_arr.push(ingredient.name);
      });
      return ingredients_arr.join(", ");
    },
    // Our method to GET results from a Laravel endpoint
    getProduct() {
      this.loading = true;
      UserService.getProduct(this.$route.params.id).then(
        (response) => {
          this.product = response.data;
          this.product.parsed_ingredients = this.parseIngredients(
            response.data.ingredients
          );
          this.loading = false;
        },
        () => {}
      );
    },
  },
  mounted() {
    this.currentUser = this.$store.state.auth.user;
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.getProduct();
    this.getResults();
  },
};
</script>

<style scoped>
.flex {
  display: flex;
}

.size {
  margin: 0px;
  padding: 0px;
  display: inline-block;
  list-style: none;
}

.size p {
  float: left;
  margin-top: 10px;
  font-weight: 500;
  padding-right: 6px;
}

.size-box {
  padding: 12px;
  margin: 0px 5px;
  display: inline-block;
  color: #000;
  font-weight: 400;
}

.header {
  padding: 10px;
  border-bottom: 2px solid var(--primary-color);
  width: 100%;
}

.header p {
  padding: 0;
  font-weight: 700;
  padding: 0 10px;
  font-size: 1.1rem;
  color: var(--primary-color);
  margin-bottom: 0;
}

.v-list-item__title {
  display: flex;
  align-items: center;
}
</style>
