<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar height="80" color="var(--primary-color)" dark
          >Naruči za {{ formatDate(date) }}</v-toolbar
        >
        <v-card-text>
          <v-form
            v-model="valid"
            @submit.prevent="makeOrder"
            @keydown="form.onKeydown($event)"
            style="display: flex; flex-direction: column; height: 100%"
          >
            <v-select
              :rules="rules.required"
              class="mt-3"
              v-model="form.product_id"
              :items="products"
              label="Odaberi proizvod*"
              item-text="name"
              item-value="id"
            >
              <template v-slot:selection="slotProps">
                <v-img
                  :src="BASE_URL + slotProps.item.image"
                  max-width="40"
                  height="40"
                  class="mr-2"
                  alt=""
                />
                {{ slotProps.item.name }}
              </template>
              <template v-slot:item="slotProps">
                <v-img
                  lazy-src="@/assets/images/foodph.png"
                  :src="BASE_URL + slotProps.item.image"
                  max-width="40"
                  height="40"
                  class="mr-2"
                  alt=""
                />
                {{ slotProps.item.name }}
              </template>
            </v-select>
            <v-textarea
              style="align-items: flex-end"
              no-resize
              outlined
              :rules="[rules.lengthMax(100)]"
              counter="100"
              name="input-7-4"
              v-model="form.note"
              :label="$t('orders.edit.note')"
            ></v-textarea>
            <has-error field="note" :form="form" />
          </v-form>
          <snackbar
            v-if="visible"
            :form="form"
            :timeout="timeout"
            :snackbar="snackbar"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog.value = false">
            {{ $t("orders.edit.cancel") }}
          </v-btn>
          <v-btn
            color="var(--primary-color)"
            @click="makeOrder"
            :disabled="!valid || processingOrder"
            class="white--text"
          >
            <circle-svg v-if="processingOrder" class="w-6 h-6 mr-2" />
            {{ $t("orders.edit.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import Form from "vform";
import { API_URL } from "@/config";
import { BASE_URL } from "@/config";
import authHeader from "@/services/auth-header";
import Snackbar from "@/components/Snackbar";
import UserService from "@/services/user.service";
import HasError from "@/components/HasError";
import CircleSvg from "@/components/CircleSvg";
import i18n from "@/plugins/i18n";
import moment from "moment";

export default {
  name: "NewOrderCalendar",
  components: { Snackbar, HasError, CircleSvg },
  data() {
    return {
      rules: {
        required: [(v) => !!v || "Obavezno polje!"],
        lengthMax: (len) => (v) =>
          (v || "").length <= len || `${i18n.tc("home.order.below")} ${len}.`,
      },
      form: new Form({
        note: "",
        delivery_date: null,
        product_id: 1,
      }),
      user: null,
      BASE_URL: BASE_URL,
      products: null,
      dialog: false,
      date: "",
      valid: false,
      dialogRate: false,
      visible: false,
      timeout: 1200,
      processingOrder: false,
      snackbar: {
        icon: "",
        title: "",
        color: "",
      },
    };
  },
  props: {
    getOrders: Function,
    selectedEvent: Object,
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY.");
    },
    onUpdated() {
      this.$emit("updated");
    },
    changeData(event) {
      this.form.product_id = event.product_id;
      this.form.note = event.note;
    },
    async makeOrder() {
      this.processingOrder = true;
      await this.form
        .post(API_URL + `order`, {
          headers: authHeader(),
        })
        .then(() => {
          let day = (
            moment().weekday() == 5
              ? moment().add(3, "days")
              : moment().weekday() == 6
              ? moment().add(2, "days")
              : moment().add(1, "days")
          ).format("YYYY-MM-DD");
          if (this.form.delivery_date.includes(day)) {
            this.$store.commit("orderedForTomorrow", true);
          }

          this.processingOrder = false;
          this.dialog = false;
          this.form.reset();
          this.onUpdated();
        })
        .catch(() => {
          this.snackbar.icon = "mdi-alert-circle";
          this.snackbar.title = this.$t("snackbar.error");
          this.snackbar.color = "red";
          this.processingOrder = false;
          this.visible = true;
        });
    },
  },
  mounted() {
    UserService.getProducts().then(
      (response) => {
        this.products = response.data.data;
        this.loading = false;
      },
      () => {}
    );
  },
};
</script>

<style lang="scss" scoped></style>
