<template>
  <v-card class="mx-auto my-3" max-width="344">
    <v-img height="180" :src="image"> </v-img>

    <v-card-title
      style="
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      "
    >
      <p class="black--text ma-0 pa-0">{{ name }}</p>
    </v-card-title>

    <v-card-text>
      {{ suppliers }}
    </v-card-text>

    <v-divider class="mx-4 mb-2"></v-divider>

    <v-card-actions class="mt-0 pt-0 justify-center">
      <v-btn
        color="var(--primary-color)"
        class="px-5"
        outlined
        text
        @click.prevent="openMenu(id)"
      >
        {{ $t("home.order.look") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { BASE_URL } from "@/config";
export default {
  data: () => ({
    BASE_URL: BASE_URL,
  }),
  props: {
    name: {
      type: String,
    },
    suppliers: {
      type: String,
    },
    image: {
      type: String,
    },
    id: {
      type: Number,
    },
  },
  methods: {
    openMenu(id) {
      if (id) {
        this.$router.push({
          name: "lunch",
        });
      } else {
        this.$router.push({
          name: "breakfast",
        });
      }
    },
  },
  mounted() {},
};
</script>
