<template>
  <div class="cont">
    <div class="wrapper">
      <div class="landing-page">
        <div style="text-align: center" class="icon__download">
          <img src="@/assets/images/404-error.svg" />
        </div>

        <h1>{{ $t("err404.title") }}</h1>
        <p>{{ $t("err404.subtitle") }}</p>
        <router-link class="button" to="/home">
          {{ $t("err404.back_home") }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Err404",
};
</script>

<style scoped>
* {
  font-family: sans-serif;
  color: rgba(0, 0, 0, 0.75);
}
.cont {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.wrapper {
  max-width: 1260px;
  width: 100%;
  margin: 30px auto;
  transform: scale(0.8);
}
.landing-page {
  max-width: 1260px;
  height: 675px;
  margin: 0;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
img {
  width: 70%;
  max-height: 225px;
  height: auto;
  margin: 0 0 15px;
}
h1 {
  font-size: 68px;
  margin: 0;
}
p {
  font-size: 20px;
  width: 35%;
  margin: 16px auto 24px;
  text-align: center;
}
.button {
  border-radius: 50px;
  padding: 8px 24px;
  font-size: 20px;
  cursor: pointer;
  background: var(--primary-color);
  color: #fff;
  border: none;
  box-shadow: 0 4px 8px 0 #ccc;
}
</style>
