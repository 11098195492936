import createPersistedState from "vuex-persistedstate";
import i18n, { selectedLocale } from "../plugins/i18n";

export const locales = {
  state: {
    locale: selectedLocale,
  },
  mutations: {
    updateLocale(state, newLocale) {
      state.locale = newLocale;
    },
  },
  actions: {
    changeLocale({ commit }, newLocale) {
      i18n.locale = newLocale; // important!
      localStorage.setItem("lang", newLocale);
      commit("updateLocale", newLocale);
    },
  },
  plugins: [createPersistedState()],
};
