import i18n from "@/plugins/i18n";

class SnackbarService {
  //Set snackbar to success message
  setSnackbarSuccess(snackbar) {
    snackbar.icon = "mdi-check-circle";
    snackbar.title = i18n.tc("snackbar.success");
    snackbar.color = "success";
  }
  //Set snackbar to error message
  setSnackbarError(snackbar) {
    snackbar.icon = "mdi-alert-circle";
    snackbar.title = i18n.tc("snackbar.error");
    snackbar.color = "red";
  }
}

export default new SnackbarService();
