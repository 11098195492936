import { render, staticRenderFns } from "./RadioButtonComponent.vue?vue&type=template&id=3973d938&scoped=true&"
import script from "./RadioButtonComponent.vue?vue&type=script&lang=js&"
export * from "./RadioButtonComponent.vue?vue&type=script&lang=js&"
import style0 from "./RadioButtonComponent.vue?vue&type=style&index=0&id=3973d938&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3973d938",
  null
  
)

export default component.exports