import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import { store } from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import vuetify from "./plugins/vuetify";
import "vuetify/dist/vuetify.min.css";
import "@/assets/styles/colors.css";
import "@/assets/styles/scrollbar.css";
import "./plugins";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

import firebase from "firebase/app";
import "firebase/messaging";
import i18n from "./plugins/i18n";

// import plugin
import VueToastr from "vue-toastr";
// use plugin
Vue.use(VueToastr);
Vue.component("pagination", require("laravel-vue-pagination"));

firebase.initializeApp({
  messagingSenderId: "398237234904",
  projectId: "foodstomachofficer",
  apiKey: "AIzaSyBLpnOqS9G1NmOyGkzjz564pkEHCmYR94M",
  appId: "1:398237234904:web:b9237cf89d7223e01749ad",
});

navigator.serviceWorker
  .register("firebase-messaging-sw.js", {
    scope: "firebase-cloud-messaging-push-scope",
  })
  .then((registration) => {
    const messaging = firebase.messaging();
    messaging.useServiceWorker(registration);
  })
  .catch((err) => {
    console.log(err);
  });

import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

// fiebase configs end
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  axios,
  VueAxios,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
