<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs16 sm12 md6 lg6>
            <v-card class="elevation-6">
              <v-toolbar dark color="var(--primary-color)">
                <img
                  src="@/assets/images/logo.svg"
                  class="logo"
                  height="180"
                  alt=""
                />
                <v-toolbar-title style="z-index: 20; line-height: inherit">{{
                  $t("auth.login")
                }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form
                  ref="form"
                  @submit.prevent="handleLogin"
                  v-model="valid"
                >
                  <v-text-field
                    v-model="user.email"
                    :rules="emailRules"
                    :placeholder="!autofilled ? ' ' : ''"
                    :label="$t('auth.email')"
                    prepend-icon="mdi-email-outline"
                    name="username"
                    required
                  ></v-text-field>
                  <v-text-field
                    autocomplete="off"
                    v-model="user.password"
                    :placeholder="!autofilled ? ' ' : ''"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    :type="show ? 'text' : 'password'"
                    :label="$t('auth.password')"
                    prepend-icon="mdi-lock"
                    @click:append="show = !show"
                  ></v-text-field>
                  <v-checkbox
                    :label="$t('auth.remember_me')"
                    hide-details
                    class="mb-6"
                    color="var(--primary-color)"
                    v-model="rememberMe"
                  ></v-checkbox>
                  <v-btn
                    :disabled="!valid || loading"
                    color="var(--primary-color)"
                    class="mr-4 white--text"
                    type="submit"
                    @click="handleLogin"
                  >
                    <circle-svg v-if="loading" class="w-6 h-6 mr-2" />
                    {{ $t("auth.login") }}
                  </v-btn>

                  <a
                    href="https://fso-admin.amplitudo.me/password/reset"
                    target="_blank"
                    >Zaboravili ste lozinku?</a
                  >
                </v-form>
              </v-card-text>
              <p v-if="message" class="px-3 pb-1 mt-0 color-red">
                {{ message }}
              </p>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import auth_strings from "../lang/en/auth.json";
import User from "../models/user";
import { API_URL } from "@/config";
import axios from "axios";
import authHeader from "@/services/auth-header";
import firebase from "firebase/app";

import CircleSvg from "@/components/CircleSvg";

export default {
  name: "Login",

  components: {
    CircleSvg,
  },
  data() {
    return {
      auth_strings: auth_strings,
      user: new User("", ""),
      API_URL: API_URL,
      loading: false,
      message: "",
      errors: null,
      autofilled: false,
      valid: true,
      rememberMe: false,
      passwordRules: [(v) => !!v || this.$t("auth.password_required")],
      show: false,
      emailRules: [(v) => !!v || this.$t("auth.email_required")],
    };
  },
  watch: {
    "user.email"() {
      this.autofilled = true;
    },
    "user.password"() {
      this.autofilled = true;
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/home");
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;

      if (this.user.email && this.user.password) {
        let data = {
          email: this.user.email,
          password: this.user.password,
        };
        this.$store.dispatch("auth/login", data).then(
          () => {
            if (this.rememberMe) {
              localStorage.setItem("remember_me", true);
            } else {
              localStorage.setItem("remember_me", false);
            }
            this.$router.push("/home");
            firebase
              .messaging()
              .requestPermission()
              .then(() => {
                return firebase
                  .messaging()
                  .getToken()
                  .then((token) => {
                    const data = {
                      token: token,
                    };
                    localStorage.setItem("fcm_token", token);
                    axios
                      .post(API_URL + "store-token", data, {
                        headers: authHeader(),
                      })
                      .then(() => {})
                      .catch(() => {});
                  })
                  .catch(() => {});
              });
          },
          () => {
            this.loading = false;
            this.message = this.$t("auth.invalid_credentials");
          }
        );
      }
    },
  },
};
</script>

<style>
.v-btn__content {
  color: white;
}
.color-red {
  color: red;
}
.w-6 {
  width: 1.6rem;
}
.logo {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
@media screen and (max-width: 480px) {
  .logo {
    height: 120px;
  }
}
</style>
