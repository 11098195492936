import axios from "axios";
import authHeader from "./auth-header";

import { API_URL } from "@/config";

class UserService {
  getProducts() {
    return axios.get(API_URL + "product", { headers: authHeader() });
  }

  getProductsForEdit(product_id) {
    return axios.get(API_URL + "edit-products/" + product_id, {
      headers: authHeader(),
    });
  }
  getProductsByMealType(mealType, day) {
    return axios.get(API_URL + "products/" + mealType, {
      headers: authHeader(),
      params: {
        day: day,
      },
    });
  }

  hasOrderedForTomorrow() {
    return axios.get(API_URL + "ordered-for-tomorrow", {
      headers: authHeader(),
    });
  }

  orderedForFuture() {
    return axios.get(API_URL + "ordered", { headers: authHeader() });
  }

  getAvailableDays(product) {
    return axios.get(API_URL + "get-available-days/" + product, {
      headers: authHeader(),
    });
  }

  getReviews(id, page) {
    return axios.get(API_URL + "reviews/" + id + "?page=" + page, {
      headers: authHeader(),
    });
  }

  getProduct(id) {
    return axios.get(API_URL + "product/" + id, {
      headers: authHeader(),
    });
  }

  getOrders() {
    return axios.get(API_URL + "order", { headers: authHeader() });
  }

  getOffices() {
    return axios.get(API_URL + "office", { headers: authHeader() });
  }

  getStats() {
    return axios.get(API_URL + "stats", { headers: authHeader() });
  }

  getMe() {
    return axios.get(API_URL + "profile", { headers: authHeader() });
  }

  disableNotification(date) {
    return axios.post(
      API_URL + "disable-notification",
      {
        date,
      },
      { headers: authHeader() }
    );
  }

  enableNotification(date) {
    return axios.post(
      API_URL + "enable-notification",
      {
        date,
      },
      { headers: authHeader() }
    );
  }

  disabledNotifications() {
    return axios.get(API_URL + "disabled-notifications", {
      headers: authHeader(),
    });
  }
}

export default new UserService();
