<template>
  <v-data-table
    :headers="headers"
    :items="itemsData"
    item-key="name"
    class="elevation-1"
    :dark="this.themeLight"
    :items-per-page="this.itemsPerPage"
    hide-default-footer
  >
    <template #top>
      <v-toolbar flat>
        <v-toolbar-title>Statistika dosadašnjih troškova</v-toolbar-title>
        <v-toolbar-items>
          <svg
            type="button"
            class="v-icon__svg mt-5 mr-5"
            viewBox="0 0 24 24"
            role="img"
            aria-hidden="true"
            @click="changeTheme"
          >
            <path
              d="M7.5,2C5.71,3.15 4.5,5.18 4.5,7.5C4.5,9.82 5.71,11.85 7.53,13C4.46,13 2,10.54 2,7.5A5.5,5.5 0 0,1 7.5,2M19.07,3.5L20.5,4.93L4.93,20.5L3.5,19.07L19.07,3.5M12.89,5.93L11.41,5L9.97,6L10.39,4.3L9,3.24L10.75,3.12L11.33,1.47L12,3.1L13.73,3.13L12.38,4.26L12.89,5.93M9.59,9.54L8.43,8.81L7.31,9.59L7.65,8.27L6.56,7.44L7.92,7.35L8.37,6.06L8.88,7.33L10.24,7.36L9.19,8.23L9.59,9.54M19,13.5A5.5,5.5 0 0,1 13.5,19C12.28,19 11.15,18.6 10.24,17.93L17.93,10.24C18.6,11.15 19,12.28 19,13.5M14.6,20.08L17.37,18.93L17.13,22.28L14.6,20.08M18.93,17.38L20.08,14.61L22.28,17.15L18.93,17.38M20.08,12.42L18.94,9.64L22.28,9.88L20.08,12.42M9.63,18.93L12.4,20.08L9.87,22.27L9.63,18.93Z"
            ></path>
          </svg>
        </v-toolbar-items>
      </v-toolbar>
    </template>
    <template #item="{ item }">
      <tr>
        <td>{{ item.month }}</td>
        <td>{{ item.cost }} €</td>
        <td>
          <v-btn icon>
            <v-icon @click="downloadExcel(item)"> mdi-file-excel</v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
    <template #footer>
      <div
        class="v-data-footer font-weight-bold"
        style="height: 48px; font-size: 0.875rem"
      >
        <div class="v-data-footer__select">Ukupno:</div>
        <div class="v-data-footer__pagination">{{ total }} €</div>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import * as XLSX from "xlsx";

export default {
  data() {
    return {
      itemsPerPage: 12,
      themeLight: false,
      headers: [],
      itemsData: [],
      total: 0,
    };
  },
  props: {
    columns: [],
    rows: [],
  },
  methods: {
    changeTheme() {
      this.themeLight = !this.themeLight;
    },
    calculateTotal() {
      console.log(this.itemsData);
      for (let i in this.itemsData) {
        this.total += this.itemsData[i].cost;
      }
    },
    downloadExcel(item) {
      const fileName = item.month + ".xlsx"; // use the row item's name as the file name
      const headers = ["Proizvod", "Cijena", "Količina", "Zbirno"]; // column headers for the Excel file
      const data = []; // data for the Excel file, in this case only one row
      let saldo = 0;
      for (let product in item.products) {
        let arr = [];
        arr.push(item.products[product].name);
        arr.push(item.products[product].price);
        arr.push(item.products[product].quantity);
        arr.push(
          item.products[product].quantity * item.products[product].price
        );
        saldo += item.products[product].quantity * item.products[product].price;
        data.push(arr);
      }
      data.push(["-------", "-------", "-------", "-------"]);
      data.push(["Ukupno", "", "", saldo]);

      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]); // create a worksheet from the data

      const workbook = XLSX.utils.book_new(); // create a new workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, item.month); // add the worksheet to the workbook

      XLSX.writeFile(workbook, fileName); // download the Excel file
    },
  },
  mounted() {
    this.headers = this.columns;
    this.itemsData = this.rows;
    this.calculateTotal();
  },
};
</script>
