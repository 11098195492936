<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        icon
        @click.prevent="changeData(selectedEvent)"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card :key="key">
        <v-toolbar height="80" :color="selectedEvent.color" dark
          >{{ $t("orders.edit.title") }}
        </v-toolbar>
        <v-card-text>
          <v-form
            v-model="valid"
            @submit.prevent="updateOrder"
            @keydown="form.onKeydown($event)"
            style="display: flex; flex-direction: column; height: 100%"
          >
            <v-select
              class="mt-3"
              v-model="form.product_id"
              :items="products"
              :label="$t('orders.edit.select_new_product')"
              item-text="name"
              item-value="id"
            >
              <template v-slot:selection="slotProps">
                <img
                  :src="slotProps.item.image"
                  style="height: 40px; width: 40px; object-fit: cover"
                  class="m-2"
                  alt=""
                />
                {{ slotProps.item.name }}
              </template>
              <template v-slot:item="slotProps">
                <img
                  :src="slotProps.item.image"
                  style="height: 40px; width: 40px; object-fit: cover"
                  class="mr-2"
                  alt=""
                />
                {{ slotProps.item.name }}
              </template>
            </v-select>
            <v-textarea
              style="align-items: flex-end"
              no-resize
              outlined
              :rules="[rules.lengthMax(100)]"
              counter="100"
              name="input-7-4"
              v-model="form.note"
              :label="$t('orders.edit.note')"
            ></v-textarea>
            <has-error field="note" :form="form" />
          </v-form>
          <snackbar
            v-if="visible"
            :form="form"
            :timeout="timeout"
            :snackbar="snackbar"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog.value = false">
            {{ $t("orders.edit.cancel") }}
          </v-btn>
          <v-btn
            color="var(--primary-color)"
            @click="updateOrder"
            :disabled="!valid || processingOrder"
            class="white--text"
          >
            <circle-svg v-if="processingOrder" class="w-6 h-6 mr-2" />
            {{ $t("orders.edit.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import Form from "vform";
import { API_URL } from "@/config";
import { BASE_URL } from "@/config";
import authHeader from "@/services/auth-header";
import Snackbar from "@/components/Snackbar";
import UserService from "@/services/user.service";
import i18n from "@/plugins/i18n";
import CircleSvg from "@/components/CircleSvg";
import HasError from "@/components/HasError";

export default {
  name: "EditDialog",
  components: { Snackbar, CircleSvg, HasError },
  data() {
    return {
      key: 0,
      rules: {
        required: [(v) => !!v || "Obavezno polje!"],
        lengthMax: (len) => (v) =>
          (v || "").length <= len || `${i18n.tc("home.order.below")} ${len}.`,
      },
      form: new Form({
        note: "",
        product_id: null,
        product_name: null,
      }),
      user: null,
      BASE_URL: BASE_URL,
      products: null,
      dialog: false,
      dialogRate: false,
      visible: false,
      valid: false,
      timeout: 1200,
      processingOrder: false,
      snackbar: {
        icon: "",
        title: "",
        color: "",
      },
    };
  },
  props: {
    getOrders: Function,
    selectedEvent: Object,
  },
  methods: {
    getData() {
      UserService.getProductsForEdit(this.selectedEvent.start).then(
        (response) => {
          this.products = response.data.data;
          this.loading = false;
        },
        () => {}
      );
    },
    onUpdated(form) {
      let data = {
        note: form.note,
        product_id: form.product_id,
        name: form.product_name,
      };
      this.key++;
      this.$emit("updated", data);
    },
    changeData(event) {
      this.form.product_id = event.product_id;
      this.form.note = event.note;
      this.form.product_name = event.name;
    },
    async updateOrder() {
      this.processingOrder = true;
      await this.form
        .put(API_URL + `order/${this.selectedEvent.id}`, {
          headers: authHeader(),
        })
        .then(() => {
          this.processingOrder = false;
          this.onUpdated(this.form);
          this.getData();
        })
        .catch(() => {
          this.snackbar.icon = "mdi-alert-circle";
          this.snackbar.title = this.$t("snackbar.error");
          this.snackbar.color = "red";
          this.processingOrder = false;
          this.visible = true;
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped></style>
