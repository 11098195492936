<template>
  <v-container fill-height style="height: 100%">
    <v-row class="text-center h-100" align="center" justify="center">
      <v-col cols="12" lg="6" md="6">
        <h2 v-if="caseString === 'weekend'">{{ $t("navbar.cant_order") }}</h2>
        <h2 v-if="caseString === 'products'">{{ $t("navbar.no_products") }}</h2>
        <h2 v-if="caseString === 'stats'">{{ $t("navbar.no_stats") }}</h2>
      </v-col>
      <v-col>
        <img src="@/assets/images/dog.svg" alt="" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CantOrder",
  props: {
    case: {
      type: String,
      default: "weekend",
    },
  },
  data() {
    return {
      caseString: "weekend",
    };
  },

  mounted() {
    this.caseString = this.case;
  },
};
</script>

<style scoped></style>
