import axios from "axios";
import { API_URL } from "@/config";
import authHeader from "./auth-header";

class AuthService {
  login(user) {
    let token =
      localStorage.getItem("fcm_token") === null
        ? ""
        : localStorage.getItem("fcm_token");
    return axios
      .post(API_URL + "login", {
        email: user.email,
        password: user.password,
        fcm_token: token,
      })
      .then((response) => {
        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem(
            "access_token",
            JSON.stringify(response.data.access_token)
          );
        }

        return response.data;
      });
  }

  logout() {
    const data = {
      fcm_token: localStorage.getItem("fcm_token"),
    };
    axios
      .post(API_URL + "delete-token", data, { headers: authHeader() })
      .then(() => {
        localStorage.removeItem("fcm_token");
      });
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
  }

  register(user) {
    return axios.post(API_URL + "register", {
      email: user.email,
      password: user.password,
    });
  }
}

export default new AuthService();
