<template>
  <div>
    <v-layout>
      <v-flex>
        <login></login>
      </v-flex>
      <v-flex v-show="$vuetify.breakpoint.xl || $vuetify.breakpoint.lg">
        <SideImage />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Login from "../components/Login";
import SideImage from "../components/SideImage";
export default {
  name: "Home",
  components: { SideImage, Login },
};
</script>
<style>
* {
  padding: 0;
}
</style>
