import Form from "vform";
import authHeader from "@/services/auth-header";
import { API_URL } from "@/config";
import { BASE_URL } from "@/config";
import moment from "moment";
import CircleSvg from "../CircleSvg";
import HasError from "@/components/HasError";
import i18n from "@/plugins/i18n";
import UserService from "@/services/user.service";
import SnackbarService from "@/services/snackbar.service";

export default {
  name: "OrderCard",
  components: { CircleSvg, HasError },
  data: () => ({
    form: new Form({
      note: "",
      delivery_date: null,
      product_id: null,
    }),
    dump: {
      delivery_date: null,
    },
    rules: {
      lengthMax: (len) => (v) =>
        (v || "").length <= len || `${i18n.tc("home.order.below")} ${len}`,
    },
    currentDate: null,
    loading: false,
    selection: 1,
    modal: false,
    valid: false,
    componentKey: 0,
    menu: false,
    dialog: false,
    availableDates: [],
    visible: false,
    timeout: 2000,
    BASE_URL: BASE_URL,
    processingOrder: false,
    snackbar: {
      color: "red",
      icon: "mdi-alert-circle",
      title: "Error!",
    },
  }),
  props: {
    name: {
      type: String,
    },
    image: {
      type: String,
    },
    id: {
      type: Number,
    },
    ingredients: {
      type: Array,
    },
    dates: {
      type: Array,
    },
  },
  methods: {
    closeModal() {
      this.dialog = false;
      this.form.reset();
      this.componentKey++;
    },
    save: function (date) {
      var index = this.form.delivery_date.findIndex((x) => x === date);

      if (index === -1) {
        this.form.delivery_date.push(date);
      } else {
        this.form.delivery_date.splice(index, 1);
      }
    },
    remove(item) {
      this.form.delivery_date.splice(this.form.delivery_date.indexOf(item), 1);
      this.form.delivery_date = [...this.form.delivery_date];
    },
    getString: function (dt_string) {
      return moment(dt_string).format("D.M.Y");
    },
    required(value) {
      if (value instanceof Array && value.length == 0) {
        return "Obavezno polje.";
      }
      return !!value || "Moraš izabrati datum dostave.";
    },
    getAvailableDays() {
      UserService.getAvailableDays().then(
        (response) => {
          this.availableDates = response.data;
        },
        () => {}
      );
    },
    onOrdered() {
      this.$emit("ordered");
    },
    async makeOrder() {
      this.processingOrder = true;
      await this.form
        .post(API_URL + "order", { headers: authHeader() })
        .then(() => {
          let day = (
            moment().weekday() === 5
              ? moment().add(3, "days")
              : moment().weekday() === 6
              ? moment().add(2, "days")
              : moment().add(1, "days")
          ).format("YYYY-MM-DD");
          if (this.form.delivery_date.includes(day)) {
            this.$store.commit("orderedForTomorrow", true);
            // let data = { pid: this.form.product_id };
            this.$emit("changeFlag", this.form.product_id);
          }
          this.dialog = false;
          this.form.reset();
          this.onOrdered();
          this.form.delivery_date = null;

          this.processingOrder = false;
        })
        .catch(() => {
          SnackbarService.setSnackbarSuccess(this.snackbar);
          this.visible = true;
          this.processingOrder = false;
        });
    },
    allowedDates(a) {
      return this.dates.includes(a);
    },
    pickerUpdate: function (val) {
      let totalDay = moment(val, "YYYY-MM").daysInMonth();

      let availableDates = [];

      let monthNow = moment().format("M");
      let monthSelected = moment(val).format("M");
      let day;

      if (monthNow === monthSelected) day = moment().format("D");
      else day = 1;
      // console.log(this.dates);
      // for (let a = 0; a < this.dates.length; a++) {
      for (let i = day; i <= totalDay; i++) {
        // console.log(this.dates[a].delivery_date);
        let date = moment()
          .month(val.split("-")[1] - 1)
          .date(i)
          .format("YYYY-MM-DD");
        // console.log(date !== this.dates[a].delivery_date);
        if (moment(date).day() !== 0 && moment(date).day() !== 6)
          availableDates.push(date);
      }
      let arr = [];
      for (let i = 0; i < this.dates.length; i++) {
        arr = availableDates.filter((date) => {
          if (date !== this.dates[i].delivery_date) {
            return date;
          }
        });
      }
      this.availableDates = arr;
      this.allowedDates();
    },
  },
};
