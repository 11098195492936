<template>
  <div>
    <Navbar></Navbar>
    <div class="pa-lg-12 pa-2">
      <div id="stats">
        <v-container>
          <v-row>
            <v-col>
              <DataTable
                :columns="headers"
                :rows="rows"
                v-if="rows.length > 0"
              ></DataTable>
              <cant-order v-if="rows.length === 0" case="stats" />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import DataTable from "../components/DataTable.vue";
import UserService from "../services/user.service";
import CantOrder from "../components/CantOrder.vue";

export default {
  name: "Statistika",
  components: { CantOrder, DataTable, Navbar },
  data() {
    return {
      rows: [],
      headers: [
        { text: "Mjesec", sortable: false },
        { text: "Trošak" },
        { text: "Preuzmi", sortable: false },
      ],
      products: [],
    };
  },
  mounted() {
    this.currentUser = this.$store.state.auth.user;
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    UserService.getStats().then((response) => {
      for (let key in response.data) {
        this.rows.push({
          month: key,
          cost: response.data[key].total_price,
          products: response.data[key].products,
        });
      }
    });
  },
};
</script>
